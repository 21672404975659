<template>
<div class="scroll-y-con">

  <div class="top-title">
    <div>{{`订单ID: ${orderNo} (${formatDetailOrderStatus(data.order_status)})`}}
      <template v-if="data.order_status == 1 && data.order_source == 15">
        <a-button type="link" @click="showPay=true">选择支付方式</a-button>
        <pay-tip />
      </template>
    </div>
    <a-button
      type="link"
      v-if="[1,2,3].includes(data.order_status)"
      class="mr-4 p-0"
      @click="isShowCancel = true"
    >取消订单</a-button>
  </div>

  <div class="page-content">
    <div>
      <a-row>
        <a-col :span="8">
          <span>{{data.order_source == 15 ? '下单人' : '昵称'}}：</span>
          <span>{{ data.place_order_name }}</span>
        </a-col>
        <a-col :span="8">
          <span>{{data.order_source == 15 ? '下单手机号' : '绑定手机号'}}：</span>
          <span>{{ data.place_order_phone }}</span>
        </a-col>
        <a-col :span="8">
          <span>下单时间: </span>
          <span>{{ data.order_add_time }}</span>
        </a-col>
      </a-row>

      <a-row class="mt-3">
        <a-col :span="24" class="flex">
          <span>下单备注: </span>
          <!-- <span>{{ data.order_remarks }}</span> -->
          <a-textarea class="ml-2" style="flex:1;"
              v-model="data.order_remarks"
              :auto-size="{ minRows: 1, maxRows: 8 }"></a-textarea>
        </a-col>
      </a-row>
      <a-row class="mt-3">
        <a-col :span="24">
          <span>客服备注: </span>
          <span>{{ data.customer_service_remarks }}</span>
        </a-col>
      </a-row>
      <a-row class="mt-3">
        <a-col :span="24" class="flex">
          <span>内部备注: </span>
          <div class="flex items-center ml-2" style="flex:1;">
            <a-textarea style="flex:1;"
                v-model="data.private_remarks"
                :auto-size="{ minRows: 1, maxRows: 8 }"></a-textarea>
            <a-button class="ml-2" type="primary" size="small" @click="savePrivateRemarks()">修改备注</a-button>
          </div>
        </a-col>
      </a-row>
      <a-divider />
      <a-row class="mt-3">
        <a-col :span="5">
          <span>订单金额: </span>
          <span>{{ data.order_amount }}元</span>
        </a-col>
        <a-col :span="5">
          <span>运费金额: </span>
          <span>{{ data.freight_amount }}元</span>
        </a-col>
        <a-col :span="6">
          <span style="width:110px">全场活动优惠：</span>
          <span>{{ data.all_event_offers_amount }}元</span>
        </a-col>
        <a-col :span="8">
          <span>支付状态: </span>
          <span>{{ data.pay_status == 1 ? '未支付' : '已支付' }}</span>
          <span v-if="data.pay_status == 1 && data.pay_type==10">（货到付款-{{getCODPayType(data.pay_subtype)}}）</span>
        </a-col>
      </a-row>

      <a-row class="mt-3">
        <a-col :span="5">
          <span>订单价格: </span>
          <span>{{ data.goods_amount }}元</span>
        </a-col>
        <a-col :span="5">
          <span>会员折扣: </span>
          <span>{{ data.vip_discount_amount }}元</span>
        </a-col>
        <a-col :span="6">
          <span style="width:110px">优惠券优惠：</span>
          <span>{{ data.coupon_offers_amount }}元</span>
        </a-col>
        <a-col :span="8">
          <span>支付时间: </span>
          <span>{{ data.pay_time }}</span>
        </a-col>
      </a-row>

      <div class="mt-3">
        <base-table :columnsData="pay_column" :customHeight="100" :tableData="payData" rowKey="key">
          <!-- <template #cash_delivery_amount="{record}">
            <span v-if="data.pay_status == 2 && data.pay_type == 10"
              >{{getCODPayType(data.pay_subtype)}} {{record.cash_delivery_amount}}</span>
            <span v-else>{{record.cash_delivery_amount}}</span>
          </template> -->
        </base-table>
      </div>

      <a-divider />

      <a-collapse :default-active-key="['1', '2']">
        <a-collapse-panel
          :show-arrow="false"
          key="1"
          v-if="outletOrder && outletOrder.suborder_no"
          :header="`【${outletOrder.suborder_type === 1 ? '自提' : '配送'}】子订单：${outletOrder.suborder_no}(${outletOrder.suborder_statusname})`"
        >
          <template slot="extra">
            <a v-if="data.order_status !=-1 && outletOrder.suborder_type == 2 && outletOrder.suborder_status >= 5"
              class="pt-4 pb-4"
              type="link"
              @click.stop="handlerQuery(outletOrder.suborder_type)"
            >配送进度</a>
          </template>

          <!--如果是自提订单 显示这些-->
          <template v-if="outletOrder.suborder_type === 1">
            <a-row type="flex" align="middle">
              <div class="col-item">
                <span class="order-detail-title">取货人：</span>
                <span>
                  <a-input v-model="outletOrder.pick_up_info.recipient_name" />
                </span>
              </div>
              <div class="col-item">
                <span class="order-detail-title">手机号：</span>
                <span>
                  <a-input v-model="outletOrder.pick_up_info.recipient_phone" />
                </span>
              </div>
              <div class="col-item" style="min-width:450px;">
                <div class="flex items-center flex-1">
                  <span class="order-detail-title">取货时间：</span>
                  <time-picker2 class="flex-1" :value.sync="goods_time" />
                </div>
              </div>
            </a-row>
            <a-row class="mt-3" type="flex" align="middle">
              <div class="col-item">
                <span class="order-detail-title">负责网点：</span>
                <span>{{ outletOrder.outlets_name }}</span>
                <!-- <a-button class="ml-2" type="primary" size="small"
                    v-if="isAllEdit"
                    @click="handlerSelectOutlets">修改</a-button> -->
              </div>
            </a-row>
          </template>

          <!--如果是配送订单 显示这些-->
          <template v-if="outletOrder.suborder_type === 2">
            <a-row type="flex" align="middle">
              <div class="col-item">
                <span class="order-detail-title">收货人：</span>
                <span>
                  <a-input v-model="outletOrder.delivery_info.consignee_name" />
                </span>
              </div>
              <div class="col-item">
                <span class="order-detail-title">手机号：</span>
                <span>
                  <a-input v-model="outletOrder.delivery_info.consignee_phone" />
                </span>
              </div>
              <div class="col-item" style="min-width:450px;">
                <div class="flex items-center flex-1">
                  <span class="order-detail-title">收货时间：</span>
                  <time-picker2 class="flex-1" :value.sync="goods_time" />
                </div>
              </div>
            </a-row>
            <a-row class="mt-3" type="flex" align="middle">
              <div class="col-item">
                <span class="order-detail-title">负责网点：</span>
                <span>{{ outletOrder.outlets_name }}</span>
                <a-button class="ml-2" type="primary" size="small"
                    v-if="isAllEdit"
                    @click="handlerSelectOutlets">修改</a-button>
              </div>
              <div class="col-item">
                  <template v-if="isCustomDlvr">
                    <span class="text-right" style="width:90px;">自定义运费：</span>
                    <a-input-number style="width:110px;" placeholder="运费"
                      :min="0" :step="1" 
                      v-model="newFreight" /> 
                    <span class="ml-1">元</span>
                  </template>
                </div>
              <div class="col-item flex-1">
                  <span class="text-right" style="width:70px;">收件地址：</span>
                  <template v-if="isAllEdit">
                    <a-select
                      show-search
                      v-model="outletOrder.delivery_info.consignee_address"
                      placeholder="请输入所在地址"
                      style="flex:1;width:0;"
                      :default-active-first-option="false"
                      :show-arrow="false"
                      :filter-option="false"
                      :not-found-content="null"
                      @search="handleSearch"
                      @change="handleChange"
                    >
                      <a-select-option v-for="d in addressList" :value="d.id" :key="d.id">{{ d.title }}<span class="addr-region">{{ d.region }}</span></a-select-option>
                    </a-select>
                    <a-input style="width:150px" v-model="outletOrder.delivery_info.consignee_address_detail" allowClear placeholder="请输入门牌号"></a-input>
                  </template>
                  <template v-else>
                    <span>{{outletOrder.delivery_info.consignee_address}}</span>
                  </template>
                </div>
            </a-row>
          </template>

          <div class="flex justify-between mt-4 mb-4">
            <span class="text-sm font-bold text-black">购买商品</span>
          </div>
          <base-table
            class="shop-table-class"
            ref="orderDetilTableRef"
            id="orderDetilTableID"
            :columnsData="detail_columns"
            rowKey="sku_id"
            :customHeight="400"
            :tableData="pdOrderGoodsList"
          >
            <template #status="{ text }">
              <a-tag v-if="text == 1" color="green">正常</a-tag>
              <a-tag v-else-if="text == 2" color="red">已拆单</a-tag>
              <a-tag v-else color="gray">已退款</a-tag>
            </template>
          </base-table>

          <!--蛋糕配件-->
          <div class="mt-2" v-if="outletOrder.cake_accessory_list && outletOrder.cake_accessory_list.length > 0">
            <div class="text-sm font-bold text-black">蛋糕配件</div>
            <div class v-for="(item,i) in outletOrder.cake_accessory_list" :key="i">
              <div class="mt-2 flex items-center">
                <div>
                  <span class="font-bold">{{ item.goods_name }}</span>
                  <!-- <span class="ml-2" style="color:#aaa;">{{getSkuInfo(item.order_goods_id)['flavor_name']}} / {{getSkuInfo(item.order_goods_id)['specification_name']}}</span> -->
                  <span class="ml-2" style="color:#aaa;">{{getCakeSkuInfo(item.order_goods_id)['specification_name']}}</span>
                </div>
                <div class="ml-6">
                  <span class="text-right" style="width:70px;">蜡烛:</span>
                  <birthday-candle :value.sync="item.birthday_candle"></birthday-candle>
                </div>
                <div class="ml-2">
                  <span class="text-right" style="width:70px;">餐具:</span>
                  <a-select class="ml-2" style="width:100px"
                    default-value="1" v-model="item.diners_number">
                    <a-select-option v-for="(val, index) in 41"
                      :value="index" :key="index" >{{ index }}</a-select-option>
                  </a-select>
                </div>
                <div class="ml-2">
                  <span class="text-right" style="width:70px;">一次性餐具:</span>
                  <a-select class="ml-2" style="width:100px"
                    default-value="1" v-model="item.plastic_tableware_number">
                    <a-select-option v-for="(val, index) in 41"
                      :value="index" :key="index" >{{ index }}</a-select-option>
                  </a-select>
                </div>
                <div class="ml-2">
                  <span class="text-right" style="width:70px;">生日牌:</span>
                  <a-input class="ml-2" style="width:150px;"
                    v-model="item.birthday_card" />
                </div>
                <div class="ml-2 flex items-center" style="flex:1;">
                  <span class="text-right" style="width:70px;min-width:70px;">贺卡:</span>
                  <a-textarea class="ml-2" :auto-size="{ minRows: 1, maxRows: 8 }"
                    v-model="item.birthday_greeting_card"></a-textarea>
                </div>
              </div>
            </div>
          </div>
        </a-collapse-panel>

        <a-collapse-panel
          key="2"
          v-if="isHaveExpress || splitGoodsList.length > 0"
          :header="`【快递】子订单：${expressOrder.suborder_no ? expressOrder.suborder_no : '新拆子订单'}(${expressOrder.suborder_statusname || ''})`"
          :show-arrow="false"
        >
          <template slot="extra">
            <a v-if="data.order_status !=-1 && expressOrder.suborder_status >= 2"
              class="pt-4 pb-4"
              type="link"
              @click.stop="handlerQuery(3)"
            >快递进度</a>
          </template>
          <!--如果是快递订单 显示这些-->
          <!--如果是拆单拆出来的快递单，显示这里-->
          <template>
            <a-row class="mt-3">
              <a-col class="flex items-center" :span="8">
                <span class="order-detail-title">订单金额：</span>
                <span>{{ expressOrder.suborder_amount }}元</span>
              </a-col>
              <a-col class="flex items-center" :span="8">
                <span>取货方式：</span>
                <span>快递</span>
              </a-col>
            </a-row>
            <a-row class="mt-3">
              <a-col :span="8">
                <span class="order-detail-title">收货人：</span>
                <span>{{ expressOrder.express_info.consignee_name }}</span>
              </a-col>
              <a-col :span="8">
                <span class="order-detail-title">手机号：</span>
                <span>{{ expressOrder.express_info.consignee_phone }}</span>
              </a-col>
              <a-col :span="8">
                <span class="order-detail-title">收件地址：</span>
                <span>{{ expressOrder.express_info.consignee_address }}</span>
              </a-col>
            </a-row>

            <div class="flex justify-between mt-4">
              <span class="text-sm font-bold text-black">快递商品</span>
            </div>
            <base-table
              ref="orderDetilTableRef"
              id="orderDetilTableID"
              :columnsData="detail_columns"
              :customHeight="300"
              rowKey="sku_id"
              class="mt-2"
              :tableData="splitGoodsList"
            ></base-table>
          </template>
          <template v-if="isHaveExpress">
            <template v-if="giftList.length > 0">
              <div class="flex justify-between mt-4">
                <span class="text-sm font-bold text-black">赠品</span>
              </div>
              <base-table
                ref="orderDetilTableRef"
                id="orderDetilTableID"
                :columnsData="gift_columns"
                rowKey="sku_id"
                :tableData="giftList"
              ></base-table>
            </template>
            <template v-if="conponList.length > 0">
              <div class="flex justify-between mt-4">
                <span class="text-sm font-bold text-black">赠券</span>
              </div>
              <base-table
                class="shop-table-class mt-2"
                ref="orderDetilTableRef"
                id="orderDetilTableID"
                :columnsData="conpon_columns"
                row-key="id"
                :tableData="conponList"
              >
              </base-table>
            </template>
          </template>

        </a-collapse-panel>
      </a-collapse>
    </div>
    <div>
      <a-button class="bottom-btn" type="link" @click="isShowFlow = true">查看流水</a-button>
      <a-button class="bottom-btn" type="link" @click="isShowOptLog = true">查看操作记录</a-button>
    </div>
    
    <template v-if="[1,2,3,4].includes(data.order_status)">
      <div class="bottom-space"></div>
      <div class="operate-fixed-footer flex justify-center mt-3">
        <a-button type="primary" @click="handlerSave">保存修改</a-button>
      </div>
    </template>
  </div>

  <!-- 更换配送站 -->
  <!-- <select-outlets-modal
      v-if="isSelectOutlets"
      @ok="handlerOutletsChanged"
      :show.sync="isSelectOutlets"
      :orderId="data.order_no"
      :type="outletOrder.suborder_type"
    /> -->
  <select-outlets-modal
      v-if="isSelectOutlets"
      :show.sync="isSelectOutlets"
      :outletOrder="outletOrder"
      @ok="handlerOutletsChanged" />

  <!-- 物流 -->
  <QueryWindow v-if="isShowQuery" :show.sync="isShowQuery" 
      :requestID="orderNo" 
      :hasDelivery="query_delivery" 
      :hasExpress="query_express" />
  <!-- 弹窗：流水、操作记录 -->
  <flow-modal
    v-if="isShowFlow"
    :show.sync="isShowFlow"
    :orderId="orderNo" />
  <opt-modal
    v-if="isShowOptLog"
    :show.sync="isShowOptLog"
    :orderId="orderNo" />

  <!-- 支付弹窗 -->
  <pay-modal v-if="showPay" :show.sync="showPay"
       :orderId="data.order_no"
       :orderType="data.pick_up_delivery_order.suborder_type"
       :totalAmount="Number(data.order_amount)"
       @paystatus="getPayStatus"></pay-modal>
  <!-- 支付弹窗 -->
  <repay-modal v-if="showRePay" :show.sync="showRePay"
       :orderId="order_no"
       :orderType="outletOrder.suborder_type"
       :totalAmount="Number(data.order_amount)"
       :newTotalAmount="newAmount"
       @ok="onRePayOk"></repay-modal>

  <!-- 取消弹窗（包含其他支付） -->
  <cancel-modal v-if="isShowCancel"
       :show.sync="isShowCancel"
       :payInfo="data"
       @ok="handlerCancelSure" />

</div>
</template>

<script>
import { 
  getAllOrderDetail,
  cancelCakeOrder,
  updateSelfOrder
} from "@/api/shop.js"
import { 
  updatePrivateRemarks,
} from "@/api/customer"
import { subCODPayTypeList } from '@/utils/type'
import { getMapPointList, getAdcode } from "@/utils/tMap"
import { outletSearchShopDeliveryArea } from "@/utils/delivery.js"
import pageData from "./columns"
import {
  formatBirthdayCandle,
  formatDetailOrderStatus,
  formatPdSubOrderStatus,
  formatEpSubOrderStatus,
} from "@/utils/type"
import QueryWindow from "@/components/order/queryWindow"
import FlowModal from "@/components/order/flow-modal.vue"
import OptModal from "@/components/order/opt-modal.vue"
import CancelModal from '@/components/order/cancel-modal.vue'

import BirthdayCandle from '@/components/order/birthday-candle.vue'
import TimePicker2 from "@/components/order/time-picker2.vue"

import SelectOutletsModal from './components/select-outlets-modal.vue'
// import SelectOutletsModal from "@/components/order/select-outlets-modal.vue"
import PayTip from "@/components/order/pay-tip.vue"
import PayModal from '../order/components/pay-modal.vue'
import RepayModal from './components/repay-modal.vue'

export default {
  components: {
    QueryWindow, FlowModal, OptModal, CancelModal, 
    BirthdayCandle, 
    TimePicker2, 
    SelectOutletsModal,
    PayTip,PayModal, RepayModal
  },
  props: {
    orderNo: {
      type: String,
      default: "",
    },
    num: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      ...pageData,
      requestID: "",
      isHaveExpress: false,
      splitGoodsList: [],
      expressOrder: [],

      pdOrderGoodsList: [],
      payData: [],
      giftList: [],
      conponList: [],
      data: {},
      outletOrder: {},
      isShowFlow: false,
      isShowOptLog: false,
      isShowQuery: false,

      isShowCancel: false,
      otherPayAmount: 0,

      isSelectOutlets: false,

      query_delivery: false,
      query_express: false,
      subCODPayTypeList: subCODPayTypeList,

      addressList: [],

      isTimePoint: false,
      goods_time: [],

      // 提交审核的表单
      formState: {

        order_no: "",

        pick_up_outlets_id: "",
        delivery_outlets_id: "",

        // order_remarks: "",
        // customer_service_remarks: "",
        // private_remarks: "",
      },
      openTime: "",

      isAllEdit: false,

      isCustomDlvr: false,
      isCustomFreight: false,
      newFreight: 0,
      newAmount: 0,

      showPay: false,
      showRePay: false,
    }
  },
  watch: {
    num () {
      this.initData()
    }
  },
  created() {
    // this.requestID = this.$route.params.id
    this.initData()
  },
  methods: {
    formatBirthdayCandle,
    formatDetailOrderStatus,

    async initData () {
      const { data, code, timestamp } = await getAllOrderDetail({
        order_no: this.orderNo,
      })
      if (code === 0) {
        this.openTime = timestamp

        this.data = data
        this.data.order_status_show = formatDetailOrderStatus(data.order_status)

        if (!Array.isArray(data.express_order)) {
          this.isHaveExpress = true
          this.expressOrder = data.express_order
          this.splitGoodsList = data.express_order.goods_list
          this.expressOrder.suborder_statusname = formatEpSubOrderStatus(
            data.express_order.suborder_status
          )
        }
        // 网点订单
        if (data.pick_up_delivery_order && data.pick_up_delivery_order.suborder_no) {
          let outletOrder = data.pick_up_delivery_order
          
          let time_start, time_end
          if (outletOrder.suborder_type == 1) { // 如果是自提单
            time_start = outletOrder.pick_up_info.pick_up_goods_time_start
            time_end   = outletOrder.pick_up_info.pick_up_goods_time_end
            this.formState.pick_up_outlets_id = outletOrder.outlets_id
          } else { // 如果是配送单
            // 如果时间为空 服务端都返回 1970-01-01 08:00:00
            time_start = outletOrder.delivery_info.delivery_time_start
            time_end   = outletOrder.delivery_info.delivery_time_end
            this.formState.delivery_outlets_id = outletOrder.outlets_id
            // this.formState.consignee_adcode = outletOrder.delivery_info.consignee_adcode
            outletOrder.delivery_info.consignee_lng = outletOrder.delivery_info.consignee_lng_lat.split(" ")[0]
            outletOrder.delivery_info.consignee_lat = outletOrder.delivery_info.consignee_lng_lat.split(" ")[1]
          }

          this.isTimePoint = time_start == time_end

          let date = time_start.split(" ")[0]
          let time = time_start.split(" ")[1].substr(0, 5) + "-" + time_end.split(" ")[1].substr(0, 5)
          this.goods_time = [date, time]

          // outletOrder.goods_time = [date, time]
          outletOrder.suborder_statusname = formatPdSubOrderStatus(
            data.pick_up_delivery_order.suborder_status
          )

          this.outletOrder = outletOrder
          // this.outletOrder.suborder_statusname = formatPdSubOrderStatus(
          //   data.pick_up_delivery_order.suborder_status
          // )

          this.isCustomDlvr = data.order_source == 15 && outletOrder.suborder_type == 2
          this.newFreight = data.freight_amount
        }

        this.isAllEdit = [1,2,3].includes(data.order_status)

        this.$emit('getDetail', this.data)

        this.giftList = data.event_gift_goods_list || []
        this.conponList =
          data.event_gift_coupon_list.map((item, i) => {
            item.id = i
            return item
          }) || []
        this.pdOrderGoodsList = data.pick_up_delivery_order.goods_list
        this.payData = [{
          key: "0",
          wx_pay_amount: data.wx_pay_amount,
          vip_card_pay_amount: data.vip_card_pay_amount,
          stored_value_card_pay_amount: data.stored_value_card_pay_amount,
          alipay_amount: data.alipay_amount,
          cash_delivery_amount: data.cash_delivery_amount,
          other_pay_amount: data.other_pay_amount
        }]
      }
    },

    // 地址输入
    async handleSearch(value) {
      // 根据经纬度反查Adcode
      getMapPointList(value).then((res) => {
        if (res.status == 0) {
          this.addressList = res.data.map(item=>{
            item.region = item.province+item.city+item.district
            return item
          })
        }
      })
    },
    async handleChange(value) {
      const item = this.addressList.find((item) => item.id === value)
      this.outletOrder.delivery_info.consignee_address = (item.address || '') + (item.title || '')
      this.outletOrder.delivery_info.consignee_lat = item.location.lat
      this.outletOrder.delivery_info.consignee_lng = item.location.lng
      // 根据经纬度反查Adcode
      const adcodesRes = await getAdcode(item.location.lat, item.location.lng)

      if (adcodesRes && adcodesRes.status === 0) {
        this.outletOrder.delivery_info.consignee_adcode = Number(adcodesRes.result.address_reference.town.id + '000') || ''
      } else {
        this.outletOrder.delivery_info.consignee_adcode = ''
        this.$message.warning("查询错误，请重试");
      }

      // 自动切换网点
      if(this.outletOrder.delivery_info.consignee_adcode){
        let area = await outletSearchShopDeliveryArea({
          lat: this.outletOrder.delivery_info.consignee_lat,
          lng: this.outletOrder.delivery_info.consignee_lng
        }, this.outletOrder.delivery_info.consignee_adcode)

        if (area) {
          if(this.outletOrder.outlets_name != area.outlets_name){
            this.$message.info("网点自动切换为："+area.outlets_name)
          }
          this.formState.delivery_outlets_id = area.outlets_id
          this.outletOrder.outlets_name = area.outlets_name
          if(this.isCustomDlvr){
            this.newFreight = area.freight
            this.isCustomFreight = !area.delivery_region_id
          }
        } else {
          this.$message.warning("超区，不在配送范围内");
          this.formState.delivery_outlets_id = ""
          this.outletOrder.outlets_name = ""
          if(this.isCustomDlvr){
            this.newFreight = 0
            this.isCustomFreight = false
          }
        }
      }
    },

    // 修改网点
    handlerSelectOutlets() {
      this.isSelectOutlets = true
    },
    // 确认选择网点
    handlerOutletsChanged(row){
      // if(row){
      //   this.formState.delivery_outlets_id = row.outlets_id
      //   this.outletOrder.outlets_name = row.outlets_name
      // }

      if(this.outletOrder.suborder_type==2){
        this.formState.delivery_outlets_id = row.outlets_id
        // 如果是本店下单  可以自定义运费
        if(this.isCustomDlvr){
          this.newFreight = row.freight_amount
          this.isCustomFreight = !row.delivery_region_id
        }
        this.outletOrder.outlets_name = row.outlets_name
      }
    },

    handlerSave(){
      if(this.isCustomDlvr){
        if(Number(this.newFreight) != Number(this.data.freight_amount)){
          this.newAmount = Number(this.data.goods_amount) + Number(this.newFreight)
          this.showRePay = true
          return
        }
      }

      this.submitSave()
    },

    submitSave(payParams){
      let pickParmas = {}
      let dlvrParmas = {}
      if(this.outletOrder.suborder_type == 1){
        pickParmas = {
          // pick_up_outlets_id: 
          pick_up_goods_time_start: this.goods_time[0] + " " + this.goods_time[1].split("-")[0],
          pick_up_goods_time_end:   this.goods_time[0] + " " + this.goods_time[1].split("-")[1],
          pick_up_recipient_name: this.outletOrder.pick_up_info.recipient_name,
          pick_up_recipient_phone: this.outletOrder.pick_up_info.recipient_phone,
        }
      }else{
        dlvrParmas = {
          // delivery_outlets_id
          delivery_time_start: this.goods_time[0] + " " + this.goods_time[1].split("-")[0],
          delivery_time_end:   this.goods_time[0] + " " + this.goods_time[1].split("-")[1],
          delivery_consignee_name: this.outletOrder.delivery_info.consignee_name,
          delivery_consignee_phone: this.outletOrder.delivery_info.consignee_phone,
          delivery_consignee_address: this.outletOrder.delivery_info.consignee_address + (this.outletOrder.delivery_info.consignee_address_detail||""),
          delivery_consignee_lng: this.outletOrder.delivery_info.consignee_lng,
          delivery_consignee_lat: this.outletOrder.delivery_info.consignee_lat,
          delivery_consignee_adcode: this.outletOrder.delivery_info.consignee_adcode,
        }
      }

      let params = Object.assign({}, this.formState, {
        order_no: this.data.order_no,
        open_time: this.openTime,
        cake_accessory_data: JSON.stringify(this.outletOrder.cake_accessory_list),
        place_order_name: this.data.place_order_name,
        place_order_phone: this.data.place_order_phone,
        order_remarks: this.data.order_remarks,
      }, pickParmas, dlvrParmas)

      // 运费修改
      params.freight_amount = this.data.freight_amount
      if(payParams){
        Object.assign(params, payParams, {
          freight_amount: this.newFreight
        })
      }

      updateSelfOrder(params).then(res => {
        if (res.code === 0) {
          this.$message.success("已保存")
          this.initData()
          this.$emit('order-updated')
        }
      })
    },

    async savePrivateRemarks(){
      updatePrivateRemarks({
        order_no: this.data.order_no,
        private_remarks: this.data.private_remarks
      }).then(res => {
        if (res.code === 0) {
          this.$message.success("已修改")
        }
      })
    },

    getCODPayType(subpaytype){
      subpaytype = subpaytype || 0
      let item = this.subCODPayTypeList.find(el=>{
        if(subpaytype == el.id) return el
      })
      return item && item.name || "未知"
    },
    // 物流
    handlerQuery(subOrderType) {
      this.query_delivery = subOrderType==2
      this.query_express  = subOrderType==3
      this.isShowQuery = true
    },

    // 取消订单
    handlerCancelSure(refundData){
      let params = {
        ...refundData,
        order_no: this.data.order_no
      }
      cancelCakeOrder(params).then(res => {
        if (res.code === 0) {
          this.isShowCancel = false
          this.$message.success("已取消")
          this.initData()
          this.$emit('order-canceled')
        }
      })
    },

    getPayStatus(){
      this.initData()
      this.showPay = false
    },

    onRePayOk(params){
      this.showRePay = false
      this.submitSave(params)
    },

    getCakeSkuInfo(order_goods_id){
      let sku = this.outletOrder.goods_list.find(el=>{
        return el.order_goods_id == order_goods_id
      })
      return sku || {}
    },

  },
}
</script>

<style lang="less" scoped>
.order-detail-container {
  // margin: 20px;
  padding: 20px 20px 50px 20px;
  background: #ffffff;
}
.operate-fixed-footer{
  border-top: 1px solid #eee;
  z-index: 9;
}
.col-item{
  min-height: 32px;
  min-width: 280px;
  display: flex;
  align-items: center;
}
.shop-table-class {
  // margin-left: -10px;
}
.order-detail-title {
  width: 70px;
  text-align: right;
  display: inline-block;
}

.bottom-btn{
  padding: 0;
  margin-right: 20px;
}
.pre{
  white-space: pre;
  display: inline-block;
  vertical-align: top;
}
</style>
