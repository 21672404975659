<template>
  <a-modal
    :visible.sync="show"
    title="选择网点"
    destroyOnClose
    width="50%"
    @cancel="$emit('update:show', false)"
    :footer="null"
  >
    <!-- <div class="modal-search-bar">
      <a-form-model
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        ref="searchRef"
        :model="searchForm"
      >
        <a-row>
          <a-col :span="10">
            <a-form-model-item prop="adcode" label="网点城市">
              <a-select v-model="adcode" style="width:140px;" @change="initData" placeholder="门店城市">
                <a-select-option
                  v-for="(item, index) in cityList"
                  :key="index"
                  :value="item.adcode"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="10">
            <a-form-model-item prop="outlets_name" label="网点名称">
              <a-input allowClear v-model="searchForm.outlets_name" placeholder="网点名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item style="margin-left:20px;" :labelCol="{ span: 0 }">
              <a-button @click="handlerSearch" type="primary">搜索</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div> -->
    <base-table
      ref="goodsTable"
      :rowClassName="getRowClass"
      :customHeight="300"
      :columnsData="columns"
      rowKey="outlets_id"
      :tableData="tableData"
      :total="total"
      :getListFunc="initData"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #operation="{ record }">
        <a-button type="link" @click="handlerSelect(record)">选择</a-button>
      </template>
    </base-table>
  </a-modal>
</template>

<script>
// import { getCityList } from "@/api/outlets"
import { getDeliveryOutletsList } from "@/api/outlets"

// import { IsPtInPoly } from "@/utils/tMap.js"
import { getDeliveryArea, sortOutletsListByRegion } from "@/utils/delivery.js"

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: [String, Number],
    },
    outletOrder: {
      type: Object
    },
    handlerOk: {
      type: Function,
    },
    initList: {
      type: Function,
    },
  },
  data() {
    return {
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },
      adcode: '',
      searchForm: {
        outlets_name: "",
        page: 1,
        page_count: 10,
      },
      columns: [
        {
          title: "网点名称",
          dataIndex: "outlets_name",
          align: "center",
          width: 120,
        },
        {
          title: "联系电话",
          dataIndex: "tel",
          align: "center",
        },
        {
          title: "网点类型",
          dataIndex: "outlets_type",
          align: "center",
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => this.formatType(val),
        },
        {
          title: "覆盖范围",
          dataIndex: "cover_name",
          align: "center",
          width: 120,
        },
        {
          title: "剩余运力",
          dataIndex: "transport_availability",
          align: "center",
        },

        {
          title: "有库存",
          dataIndex: "have_stock",
          align: "center",
        },
        {
          title: "运费",
          dataIndex: "freight",
          align: "center",
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          slots: {
            customRender: "operation",
          },
        },
      ],
      cityList: [],
      tableData: [],
      baseList: [],
      total: 0,
    }
  },
  mounted() {
    // this.initCityList()

    // this.adcode = this.formData.city.value
    // this.adcode = this.formData.consignee_adcode

    this.initData()
  },
  methods: {
    formatType(val) {
      return val === 1 ? '门店' : '配送点'
    },
    // async initCityList() {
    //   const { data, code } = await getCityList()
    //   if (code == 0) {
    //     this.cityList = data.list
    //     if (this.formData.outlets_adcode) {
    //       this.adcode = this.formData.outlets_adcode
    //       this.initData()
    //     }
    //   }
    // },
    async initData() {
      let res = ''
      // 如果是配送单
      if (this.outletOrder.suborder_type == 2) {
        // res = await getDeliveryOutletsList({ adcode: this.outletOrder.delivery_info.consignee_adcode })
        res = await getDeliveryOutletsList()
      } else {
        // await getPickUpOutletsList(this.searchForm)
      }
      const outletsList = sortOutletsListByRegion(res.data.list, {
        lat: this.outletOrder.delivery_info.consignee_lat,
        lng: this.outletOrder.delivery_info.consignee_lng
      })
      
      this.tableData = outletsList.map(item => {
        item.cover_name = item.is_cover === 2 ? '是' : '否'
        item.have_stock = item.have_stock === 2 ? '是' : '否'

        return item
      })
      
      this.total = res.data.total_count
    },
    // 保存
    handlerSearch() {
      this.tableData = this.baseList.filter(item => item.outlets_name.includes(this.searchForm.outlets_name))
    },
    handlerSelect(row) {
      const area = getDeliveryArea([row], {
        lat: this.outletOrder.delivery_info.consignee_lat,
        lng: this.outletOrder.delivery_info.consignee_lng
      })
      if (area) {
        this.$emit("ok", Object.assign({}, row, {
          delivery_region_id: area.delivery_region_id,
          freight_amount: area.freight,
        }))
        this.$emit("update:show", false)
      } else {
        this.$confirm({
          title: "温馨提示",
          content: '收货地址不在所选网点的配送范围，确认选择？',
          okText: "确认",
          cancelText: "取消",
          onOk: () => {
            this.$emit("ok", Object.assign({}, row, {
              delivery_region_id: '',
              freight_amount: 0,
            }))
            this.$emit("update:show", false)
          }
        })
      }

    },

    getRowClass(record) {
      if (record.is_cover === 2) {
        return 'cover-status'
      } else {
        return 'cover-disable-status'
      }
    },
    
  },
}
</script>

<style lang="less">
.ant-modal-body {
  position: relative;
  margin-bottom: 10px;
}
.cover-disable-status {
  color: #ccc;
}
</style>