export const rowKey = "order_no";
import {
  formatOrderStatus,
  formatGoodsType,
  goodsType,
  orderIsModifyList,
  candleOptions,
} from "@/utils/type";
export const columns = [
  // 门店列表
  {
    title: "订单ID",
    dataIndex: "order_no",
    align: "center",
    width: 170,
  },
  {
    title: "下单时间",
    dataIndex: "order_add_time",
    align: "center",
  },
  {
    title: "自提/收货时间",
    dataIndex: "time",
    align: "center",
    width: 200,
    slots: {
      customRender: "time",
    },
  },
  {
    title: "完成时间",
    dataIndex: "order_finish_time",
    align: "center",
  },
  {
    title: "负责网点",
    dataIndex: "actual_sale_ascription_outlets",
    align: "center",
  },
  {
    title: "订单状态",
    dataIndex: "order_status",
    align: "center",
    width: 80,
    slots: {
      customRender: "order_status",
    },
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: 120,
    slots: { customRender: "operation" },
  },
];

/********************  新增页面列表   *********************************************** */
export const add_columns = [
  // 门店列表
  // {
  //   title: "SKU",
  //   dataIndex: "sku_id",
  //   align: "center",
  // },
  // {
  //   title: "商品分类",
  //   dataIndex: "goods_type",
  //   align: "center",
  //   slots: {
  //     customRender: "type",
  //   },
  //   slotsType: "format",
  //   slotsFunc: (val) => formatGoodsType(val),
  // },
  {
    title: "商品名称",
    dataIndex: "goods_name",
    align: "center",
  },
  {
    title: "口味",
    dataIndex: "flavor_name",
    align: "center",
  },
  {
    title: "尺寸",
    dataIndex: "specification_name",
    align: "center",
  },
  {
    title: "数量",
    dataIndex: "number",
    align: "center",
    slots: { customRender: "number" },
  },
  {
    title: "商品价格",
    dataIndex: "price",
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "status",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: 140,
    slots: { customRender: "operation" },
  },
];

/****************  订单详情页     ***************************************** */
export const detail_columns = [
  // 门店列表
  // {
  //   title: "SKU",
  //   dataIndex: "order_goods_id",
  //   align: "center",
  // },
  // {
  //   title: "商品分类",
  //   dataIndex: "goods_type",
  //   align: "center",
  //   slots: {
  //     customRender: "type",
  //   },
  //   slotsType: "format",
  //   slotsFunc: (val) => formatGoodsType(val),
  // },
  {
    title: "商品名称",
    dataIndex: "goods_name",
    align: "center",
  },
  {
    title: "口味",
    dataIndex: "flavor_name",
    align: "center",
  },
  {
    title: "尺寸",
    dataIndex: "specification_name",
    align: "center",
  },
  {
    title: "数量",
    dataIndex: "number",
    align: "center",
  },
  {
    title: "商品价格",
    dataIndex: "goods_price",
    align: "center",
  },
  // {
  //   title: "订单价格",
  //   dataIndex: "order_price",
  //   align: "center",
  // },
  {
    title: "是否退费",
    dataIndex: "is_refund",
    align: "center",
    slots: {
      customRender: "type",
    },
    slotsType: "format",
    slotsFunc: (val) => formatRefund(val),
  },
  // {
  //   title: "商品状态",
  //   dataIndex: "status",
  //   align: "center",
  //   slots: {
  //     customRender: "status",
  //   },
  // },
];

// 赠品列
export const gift_columns = [
  // 门店列表
  // {
  //   title: "SKU",
  //   dataIndex: "sku_id",
  //   align: "center",
  // },
  // {
  //   title: "商品分类",
  //   dataIndex: "goods_type",
  //   align: "center",
  //   slots: {
  //     customRender: "type",
  //   },
  //   slotsType: "format",
  //   slotsFunc: (val) => formatGoodsType(val),
  // },
  {
    title: "商品名称",
    dataIndex: "goods_name",
    align: "center",
  },
  {
    title: "口味",
    dataIndex: "flavor_name",
    align: "center",
  },
  {
    title: "尺寸",
    dataIndex: "specification_name",
    align: "center",
  },
  {
    title: "数量",
    dataIndex: "number",
    align: "center",
  },
];

// 赠券列
export const conpon_columns = [
  // 门店列表
  {
    title: "优惠券",
    dataIndex: "title",
    align: "center",
  },
  {
    title: "券面金额",
    dataIndex: "reduce_cost",
    align: "center",
  },
  {
    title: "消费门槛",
    dataIndex: "least_cost",
    align: "center",
  },
  {
    title: "有效期",
    dataIndex: "term_validity",
    align: "center",
  },
];

export const pay_column = [
  {
    title: "微信",
    dataIndex: "wx_pay_amount",
    align: "center",
  },
  // {
  //   title: "钱包",
  //   dataIndex: "vip_card_pay_amount",
  //   align: "center",
  // },
  // {
  //   title: "实体卡",
  //   dataIndex: "stored_value_card_pay_amount",
  //   align: "center",
  // },
  {
    title: "货到付款",
    dataIndex: "cash_delivery_amount",
    align: "center",
    // slots: { customRender: "cash_delivery_amount" },
  },
  {
    title: "支付宝",
    dataIndex: "alipay_amount",
    align: "center",
  },
  {
    title: "其他支付",
    dataIndex: "other_pay_amount",
    align: "center",
  }
];

function formatRefund(val) {
  return val === 2 ? "是" : "否";
}

export default {
  rowKey,
  columns,
  detail_columns,
  gift_columns,
  conpon_columns,
  pay_column,

  goodsType,
  candleOptions,

  orderIsModifyList,
};

export { formatOrderStatus, formatGoodsType, candleOptions };
